<template>
  <div class="page-wrap text-center">
    <div class="session-form-hold">
      <v-card class="rounded-lg d-flex flex-column gap-6 align-center pa-7">
        <img src="/img/logo/logo-dark.png" style="max-width: 300px" />
        <div>
          <h4>Estamos em manutenção</h4>
          <p class="maintenance-description">
            Estamos trabalhando para melhorar o nosso site. Por favor, volte
            mais tarde.
          </p>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { active } from "@/themeConfig.js";

export default {
  name: "Maintenance",
  data() {
    return {
      active,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.page-wrap {
  background: radial-gradient(
      circle at 50% 50%,
      rgba(0, 0, 0, 50%) 0%,
      rgba(0, 0, 0, 90%) 90vw
    ),
    url(/img/logo/auth-background.png), black;
  display: flex;
  background-size: cover;
  background-position: center;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}

.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
